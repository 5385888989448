<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form> 
            <b-row>
                <b-col>
                    <h4 class="text-primary">
                        <feather-icon size="20" icon="PlusCircleIcon" /> Tambah Kategori</h4>
                </b-col>
            </b-row>
            <hr style="margin-top: -1px;">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Kategori Titik Rawan">
                        <validation-provider #default="{ errors }" name="Pilih Kategori Markers" rules="required">
                            <b-form-input v-model="input.kategori_titik_rawan" :state="errors.length > 0 ? false:null"
                                placeholder="Kategori Titik Rawan" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Icon Marker" v-if="input.id_kategori == '0' ">
                        <validation-provider #default="{ errors }" name="Marker" rules="required">
                            <b-form-file v-model="file" ref="file" id="customFile1" @input="uploadFile"
                                :state="errors.length > 0 ? false:null" placeholder="Ukuran Mas icon marker 40 x 40px"
                                drop-placeholder="Drop file here..." />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small><i>***File yang bisa di upload dengan extensi PNG</i></small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Icon Marker" v-if="input.id_kategori != '0' ">

                        <b-form-file v-model="file" ref="file" id="customFile1" @input="uploadFile"
                            placeholder="Ukuran Mas icon marker 40 x 40px" drop-placeholder="Drop file here..." />
                        <small><i>***File yang bisa di upload dengan extensi PNG</i></small>

                    </b-form-group>

                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="Simpan" size="sm"
                        v-if="input.id_kategori == '0'  ">
                        <feather-icon size="15" icon="PlusCircleIcon" class="mr-1" />
                        Simpan
                    </b-button>
                    <b-button variant="primary" type="submit" @click.prevent="Update" size="sm"
                        v-if="input.id_kategori != '0'  ">
                        <feather-icon size="15" icon="PlusCircleIcon" class="mr-1" />
                        Update
                    </b-button>
                    <b-button variant="warning" @click.prevent="refresh()" size="sm" class="ml-1">
                        <feather-icon size="15" icon="RefreshCcwIcon" class="mr-1" />
                        Batal
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
    import {
        ref
    } from '@vue/composition-api'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormFile
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BCardText,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormFile
        },
        mixins: [Base],
        props: {
            dataEdit: {}
        },
        data() {
            return {
                emailValue: '',
                name: '',
                required,
                email,
                fileUpload: ""
            }
        },
        computed: {
            input: {
                get: function () {
                    return this.dataEdit;
                },
                set: function (newValue) {
                    return this.dataEdit = newValue;
                },
            },

        },
        emits: ["Simpan", "Update"],
        methods: {
            uploadFile() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                const self = this;
                axios.post('/upload/marker', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file = {};
                        self.fileUpload = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            Simpan() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit("Simpan", this.input, this.fileUpload);
                        this.refresh();

                    }
                })
            },
            Update() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit("Update", this.input, this.fileUpload)
                    }
                    this.refresh();
                })
            },
            refresh() {
                this.input = {
                    id_kategori: '0'
                }
                this.file = {}
            }
        },
    }
</script>