<template>
  <div>
    <b-row>
      <b-col md="7">
        <b-card>
          <tabel :items = items @StatusData = StatusData @EditData = EditData @HapusData = HapusData></tabel>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card> 
          <form-add :dataEdit = dataEdit @Simpan = Simpan @Update  =Update></form-add>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';

  import tabel from './component/tabel.vue';
  import formAdd from './component/form_add.vue'
  import {
    BRow,
    BCol,
    BCard
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow,
      BCol,
      tabel,
      formAdd,
      BCard

    },
    data() {
      return {
        items: [], 
        dataEdit : { id_kategori : '0'}
      }
    },
    mounted() {
      this.cek_token();
      this.load_data();
    },
    mixins :[Base],
    methods: {
      EditData(value){ 
        this.dataEdit = value;
      },
      async load_data() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/titik_rawan/kategori/load_data',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async Simpan(value, file) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/titik_rawan/kategori/insert',
            data: {
              kategori_titik_rawan: value.kategori_titik_rawan,
              marker: file
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "Error", response.data.message);
            self.load_data()

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async Update(value, file) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/titik_rawan/kategori/uppdate',
            data: {
              kategori_titik_rawan: value.kategori_titik_rawan,
              marker: file,
              id_kategori : value.id_kategori
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "", response.data.message);
            self.load_data()

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async StatusData(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/titik_rawan/kategori/non_aktif',
            data: {
              id_kategori: value.id_kategori, 
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "", response.data.message);
            self.load_data()

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async HapusData(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/titik_rawan/kategori/delete',
            data: {
              id_kategori: value.id_kategori, 
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "", response.data.message);
            self.load_data()

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }

  }
</script>

<style>

</style>