<template>
    <b-row> 
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(kategori_pengaduan)="data"> 
                    <b-row>
                        <b-col md="2">
                            <b-avatar rounded="" size="60" variant="light-warning">
                                <b-img :src="data.item.marker" fluid></b-img>
                            </b-avatar>
                        </b-col>
                        <b-col md="6">
                            <small>Kategori</small>
                            <h6> {{ data.item.kategori_titik_rawan }}</h6>
                        </b-col>
                        <b-col md="4">
                            <b-button block size="sm" variant="primary" class="mb-1" @click="StatusData(data.item)" v-if="data.item.status =='Aktif'">
                                <feather-icon icon="CheckIcon" size="15"></feather-icon> Status Aktif
                            </b-button>

                            <b-button block size="sm" variant="warning" class="mb-1" @click="StatusData(data.item)"  v-if="data.item.status !='Aktif'">
                                <feather-icon icon="" size="15"></feather-icon> Status Non Aktif
                            </b-button>
                            <b-row cols="mt-1">
                                <b-col md="6">
                                    <b-button block size="sm" variant="warning" @click="EditData(data.item)">
                                        <feather-icon icon="EditIcon" size="15"></feather-icon>
                                    </b-button>
                                </b-col>
                                <b-col md="6">
                                    <b-button block size="sm" variant="danger" @click="HapusData(data.item)">
                                        <feather-icon icon="TrashIcon" size="15"></feather-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template> 
            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BImg
    } from 'bootstrap-vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BImg,
            FeatherIcon
        },
        props: {
            items: {

            }
        }, 
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                        key: 'kategori_pengaduan',
                        label: '',
                    },

                ],


            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        emits:["StatusData", "EditData", "HapusData"],
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            StatusData(value){
                this.$emit("StatusData", value)
            },
            EditData(value){
                this.$emit("EditData", value)
            },
            HapusData(value){
               if(confirm("apakah anda yakin akan menghapus data iini") == true){
                this.$emit("HapusData", value)
               }
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>